import React from "react"
import { graphql } from "gatsby"

import Img from "gatsby-image"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { renderRichText } from "../lib/renderRichText"
import { icons } from "../components/icons"

const ServicesPage = props => {
  const { contentfulServicesPage, allContentfulService } = props.data
  const info = documentToPlainTextString(contentfulServicesPage.info.json)

  return (
    <>
      <SEO
        description={info}
        title={contentfulServicesPage.title}
        lang={contentfulServicesPage.node_locale}
      />

      <Container className="pt-5 pb-5 paper">
        <h1 className="font-weight-bold">{contentfulServicesPage.title}</h1>
        <div className="mb-5">
          {renderRichText(contentfulServicesPage.info.json)}
          <hr />
        </div>

        <div>
          {allContentfulService.edges.map(({ node }) => {
            return (
              <Row
                id={node.serviceTitle}
                className="d-flex align-items-center mb-4"
                key={node.serviceTitle}
              >
                <Col
                  className="service-icon d-flex justify-content-center"
                  md={2}
                >
                  {icons[node.serviceIcon]}
                </Col>

                <Col className="service-text" md={10}>
                  <h4 className="font-weight-bold">{node.serviceTitle}</h4>
                  <div>{renderRichText(node.serviceDescription.json)}</div>
                </Col>
              </Row>
            )
          })}
        </div>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query servicePageQuery($locale: String!) {
    contentfulServicesPage(
      pageName: { eq: "services" }
      node_locale: { eq: $locale }
    ) {
      node_locale
      pageName
      info {
        json
      }
      title
    }
    allContentfulService(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          serviceIcon
          serviceTitle
          serviceDescription {
            json
          }
        }
      }
    }
  }
`
export default ServicesPage
